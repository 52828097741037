<template>
  <b-row class="align-items-end">
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="4">
      <SearchTextInput
        v-model="codeAndTitle"
        label="Search code or title"
        placeholder="Code or Title"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="4">
      <StatusDropdown
        :status-filter.sync="statusFilterValue"
        @change-status-filter="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="4">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="w-100"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
        <span class="align-middle">Search</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'

export default {
  components: {
    StatusDropdown,
    SearchTextInput
  },
  props: {
    searchCodeAndTitle: {
      type: String,
      default: ''
    },
    statusFilter: {
      type: String,
      default: null
    }
  },
  computed: {
    codeAndTitle: {
      get () {
        return this.searchCodeAndTitle
      },
      set (val) {
        this.$emit('update:search-code-and-title', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
