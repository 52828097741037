<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Knowledge Type Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="knowledgeTypeForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="knowledgeType.code"
            name="code"
            label="Code"
            trim
            :rules="{ required: false }"
          />
          <TextFieldInput
            v-model="knowledgeType.title"
            name="title"
            label="Title"
            trim
            :rules="{ required: true }"
          />
          <StatusRadioButton :status.sync="knowledgeType.status" />
          <b-row>
            <b-col>
              Created At : {{ knowledgeType.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ knowledgeType.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ knowledgeType.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ knowledgeType.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              @click="deleteItem ()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: {
    TextFieldInput,
    StatusRadioButton
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialKnowledgeType: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    knowledgeType: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.knowledgeType) === JSON.stringify(this.initialKnowledgeType)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.knowledgeType.id,
        code: this.knowledgeType.code,
        title: this.knowledgeType.title,
        status: this.knowledgeType.status
      }
      this.$emit('update-knowledge-type', payload)
    },
    resetForm () {
      this.knowledgeType = { ...this.initialKnowledgeType }
      this.$refs.knowledgeTypeForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.knowledgeType.id,
        Name: this.knowledgeType.knowledgeType
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>
